class BeautPlayerQueue {
    static queue = [];
}

// see components/tracklist/TrackList.js for more info
// [
//     {
//         'trackId': ...,
//         'audioSrc': ...,
//         'audioDuration': ...,
//         'track': ...,
//         'albumArt': ...,
//         'albumTitle': ...,
//         'albumArtist': ...,
//         'isPlaylist': ...,
//         'playlistTitle': ...,
//         'linkBack': ...
//     },
//     ...
// ]

export default BeautPlayerQueue;
